@value colorBlueButton, colorBlueButtonHover, colorRed, colorRedSaturated, colorRedAlt, colorBlueAlt, colorBlue, colorBlueText from '../../styles/colors.module.css';

.button {
  color: #ffffff;
  text-decoration: none;
  transition: all 200ms linear;
  /* font-family: 'Inter var'; */
  font-family: Montserrat;
  @apply
  relative
  block
  cursor-pointer
  shadow-md
}

/* .buttonColorRed {
  background-color: colorRedSaturated;
  border: 1px solid colorRedSaturated;
}

.buttonColorRed:hover {
  background-color: colorRedAlt;
  border: 1px solid colorRedAlt;
}

.buttonColorBlue {
  background-color: colorBlue;
  border: 1px solid colorBlueText;
}

.buttonColorBlue:hover {
  background-color: colorBlueAlt;
  border: 1px solid colorBlueText;
}

.buttonColorOrange {
  background-color: colorOrangeButton;
  border: 1px solid colorOrangeButton;
}

.buttonColorOrange:hover {
  background-color: colorOrangeButtonAlt;
  border: 1px solid colorOrangeButtonAlt;
} */

.buttonColorPink {
  background-color: #fff;
  border: 3px solid hsl(316deg 65% 68%);
  color: hsl(316deg 65% 58%);
}

.buttonColorPink:hover {
  background-color: #fff;
  border: 3px solid hsl(316deg 85% 62%);
  color: hsl(316deg 85% 52%);
}

.buttonColorNavy {
  background-color: hsl(221deg 39% 28%);
  border: 3px solid hsl(221deg 39% 28%);
  color: #f7f7f7;
}

.buttonColorNavy:hover {
  background-color: hsl(221deg 65% 32%);
  border: 3px solid hsl(221deg 65% 32%);
  color: #fff;
}

.buttonColorOrange {
  background-color: #fff;
  border: 3px solid hsl(26deg 65% 68%);
  color: hsl(26deg 65% 55%);
}

.buttonColorOrange:hover {
  background-color: #fff;
  border: 3px solid hsl(26deg 55% 62%);
  color: hsl(26deg 85% 49%);
}

.buttonColorGreen {
  background-color: #fff;
  border: 3px solid hsl(97deg 85% 62%);
  color: hsl(97deg 85% 43%);
}

.buttonColorGreen:hover {
  background-color: #fff;
  border: 3px solid hsl(97deg 75% 54%);
  color: hsl(97deg 100% 38%);
}

.buttonColorBlue {
  background-color: #fff;
  border: 3px solid hsl(191deg 65% 68%);
  color: hsl(191deg 65% 55%);
}

.buttonColorBlue:hover {
  background-color: #fff;
  border: 3px solid hsl(191deg 55% 62%);
  color: hsl(191deg 85% 49%);
}

.buttonColorBlack {
  background-color: #333;
  color: #fff;
  border: 3px solid #333;
}

.buttonColorBlack:hover {
  background-color: #222;
  color: #fff;
  border: 3px solid #222;
}

.buttonColorTeal {
  /* background-color: hsl(174deg 83% 44%); */
  background-color: hsl(200deg 100% 51%);
  border: 1px solid hsl(200deg 79% 66%);
  color: #fff;
}

.buttonColorTeal:hover {
  background-color: hsl(200deg 100% 61%);
  border: 1px solid hsl(200deg 79% 76%);
  color: #fff;
}

.buttonColorPurple {
  /* background-color: hsl(174deg 83% 44%); */
  /* border: 1px solid hsl(277deg 79% 66%); */
  color: #fff;
  @apply
  bg-indigo-500
  border
  border-indigo-400
}

.buttonColorPurple:hover {
  /* background-color: hsl(277deg 100% 61%); */
  /* border: 1px solid hsl(277deg 79% 76%); */
  @apply
  bg-indigo-600
  border-indigo-500
}

.buttonColorGray {
  /* background-color: hsl(174deg 83% 44%); */
  background-color: #777;
  color: #fff;
  border: 3px solid #777;
}

.buttonColorGray:hover {
  background-color: #777;
  color: #fff;
  border: 3px solid #777;
}






.button.isDisabled,
.button.isDisabled:hover {
  cursor: not-allowed;
  background-color: #fff;
  background: #f3f3f3;
  color: #999;
  border: 1px solid #ddd;
  pointer-events: none;
  touch-action: none;
}

.large {
  composes: button;
  font-size: 24px;
  border-radius: 6px;
  padding: 16px 32px;
  min-width: 180px;
}

.medium {
  composes: button;
  border-radius: 4px;
  @apply
  border
  border-transparent
  px-5
  py-3
  text-base
  font-medium
  text-white
  shadow
  focus:outline-none
  focus:ring-2
  focus:ring-offset-2
  sm:px-10
}

.small {
  composes: button;
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
}

.tiny {
  composes: button;
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
}

.smallText {
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: transparent;
  color: #444;
  border: 1px solid transparent;
}

.secondary {
  composes: button;
  background-color: #ffffff;
  border: 1px solid #ffffff;
  background: linear-gradient(75deg, #fff, #fafafa);
}

.button.secondary:hover {
  background: linear-gradient(75deg, #fff7f7, #fff9f9 );
  border: 1px solid #ffffff;
}

.secondary.buttonColorRed {
  border-color: colorRed;
  color: colorRed;
}

.secondary.buttonColorRed:hover {
  border-color: colorRedAlt;
  color: colorRedAlt;
}

.secondary.buttonColorBlue {
  border-color: colorBlue;
  color: colorBlue;
}

.secondary.buttonColorBlue:hover {
  border-color: colorBlueAlt;
}

.secondary.buttonColorBlue {
  border-color: colorBlue;
  color: colorBlue;
}

.secondary.buttonColorBlue:hover {
  border-color: colorBlueAlt;
}

.buttonNoWrap {
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: center;
}

/* hsl(313deg 65% 68%) */
/* hsl(147deg 65% 68%) */
