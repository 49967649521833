@value colorRed from '../../styles/colors.module.css';

.footer {
  margin-top: 220px;
  margin-bottom: 12px;
  font-size: 13px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
}

.link {
  color: #666;
  margin-right: 8px;
  white-space: nowrap;
}

.linkEmphasized {
  composes: link;
  color: colorRed;
  font-weight: bold;
  margin-right: 8px;
}

.footerRow {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  gap: 8px 16px;
  flex-wrap: wrap;
}
