.repurposingView {
  font-family: 'Montserrat';
}

.heroIcon {
  flex: 0 0 300px;
  @apply
  relative
  h-2/5
  scale-75
  -mt-20
  /* sm:-mt-32 */
  -mb-4
}

.headlineText {
  flex: 1 1 100%;
  @apply
  sm:text-left
  sm:mr-4
}

.faqItem {
  @apply
  my-12
}

.faqQuestion {
  @apply
  mt-4
  max-w-3xl
  text-lg
  mx-auto
  font-bold
  text-left
}

.faqAnswer {
  @apply
  mt-4
  max-w-3xl
  text-base
  mx-auto
  text-left
}
