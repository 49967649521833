.BannerForLaunch_bannerForLaunch__Rdgl8 {

    --tw-bg-opacity: 1;

    background-color: rgb(51 65 85 / var(--tw-bg-opacity));

    padding: 1rem;

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.BannerForLaunch_content__4R_kE {

    margin-left: auto;

    margin-right: auto;

    display: flex;

    max-width: 28rem;

    align-items: center;

    gap: 1rem;

    text-align: center
}

.PageForMarketing_pageForMarketing__cNZeu {

}

.PageWithTailwind_pageWithTailwind__jwJ4F {
  --main-theme-color: #4f46e5;
  --button-color-primary: #6366f1;
  --button-color-hover: #818cf8;
  --button-text-color: #fff;
  --button-color-secondary: #3b82f6;
  --button-color-secondary-hover: #60a5fa;
  --button-text-color-secondary: #fff;
  --button-color-tertiary: transparent;
  --button-color-tertiary-hover: #f3f4f6;
  --button-text-color-tertiary: #374151;
  min-height: 100vh}

/* @value colorOrange: #ed9f47; */
/* @value colorOrangeBackground: hsl(32deg, 82%, 80%); */
/* Gray text cannot be lighter than this on a white background without comprimising accessibility */

.footer_footer__SH230 {
  margin-top: 220px;
  margin-bottom: 12px;
  font-size: 13px;
  padding: 0 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #666;
}

.footer_link__T2JpO {
  color: #666;
  margin-right: 8px;
  white-space: nowrap;
}

.footer_linkEmphasized__qLU2h {
  color: #ed4c47;
  font-weight: bold;
  margin-right: 8px;
}

.footer_footerRow__js0GD {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 12px;
  gap: 8px 16px;
  flex-wrap: wrap;
}

.MobileMenu_mobileMenu__4x_0N {

}

.linkButton_button__C3CTu {
  color: #ffffff;
  text-decoration: none;
  transition: all 200ms linear;
  /* font-family: 'Inter var'; */
  font-family: Montserrat;
  position: relative;
  display: block;
  cursor: pointer;
  --tw-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)}

/* .buttonColorRed {
  background-color: colorRedSaturated;
  border: 1px solid colorRedSaturated;
}

.buttonColorRed:hover {
  background-color: colorRedAlt;
  border: 1px solid colorRedAlt;
}

.buttonColorBlue {
  background-color: colorBlue;
  border: 1px solid colorBlueText;
}

.buttonColorBlue:hover {
  background-color: colorBlueAlt;
  border: 1px solid colorBlueText;
}

.buttonColorOrange {
  background-color: colorOrangeButton;
  border: 1px solid colorOrangeButton;
}

.buttonColorOrange:hover {
  background-color: colorOrangeButtonAlt;
  border: 1px solid colorOrangeButtonAlt;
} */

.linkButton_buttonColorPink__Xfd_e {
  background-color: #fff;
  border: 3px solid hsl(316deg 65% 68%);
  color: hsl(316deg 65% 58%);
}

.linkButton_buttonColorPink__Xfd_e:hover {
  background-color: #fff;
  border: 3px solid hsl(316deg 85% 62%);
  color: hsl(316deg 85% 52%);
}

.linkButton_buttonColorNavy__AUBSx {
  background-color: hsl(221deg 39% 28%);
  border: 3px solid hsl(221deg 39% 28%);
  color: #f7f7f7;
}

.linkButton_buttonColorNavy__AUBSx:hover {
  background-color: hsl(221deg 65% 32%);
  border: 3px solid hsl(221deg 65% 32%);
  color: #fff;
}

.linkButton_buttonColorOrange__2Fl5r {
  background-color: #fff;
  border: 3px solid hsl(26deg 65% 68%);
  color: hsl(26deg 65% 55%);
}

.linkButton_buttonColorOrange__2Fl5r:hover {
  background-color: #fff;
  border: 3px solid hsl(26deg 55% 62%);
  color: hsl(26deg 85% 49%);
}

.linkButton_buttonColorGreen__Sgif4 {
  background-color: #fff;
  border: 3px solid hsl(97deg 85% 62%);
  color: hsl(97deg 85% 43%);
}

.linkButton_buttonColorGreen__Sgif4:hover {
  background-color: #fff;
  border: 3px solid hsl(97deg 75% 54%);
  color: hsl(97deg 100% 38%);
}

.linkButton_buttonColorBlue__VOV9E {
  background-color: #fff;
  border: 3px solid hsl(191deg 65% 68%);
  color: hsl(191deg 65% 55%);
}

.linkButton_buttonColorBlue__VOV9E:hover {
  background-color: #fff;
  border: 3px solid hsl(191deg 55% 62%);
  color: hsl(191deg 85% 49%);
}

.linkButton_buttonColorBlack__xwPIa {
  background-color: #333;
  color: #fff;
  border: 3px solid #333;
}

.linkButton_buttonColorBlack__xwPIa:hover {
  background-color: #222;
  color: #fff;
  border: 3px solid #222;
}

.linkButton_buttonColorTeal__xtyRH {
  /* background-color: hsl(174deg 83% 44%); */
  background-color: hsl(200deg 100% 51%);
  border: 1px solid hsl(200deg 79% 66%);
  color: #fff;
}

.linkButton_buttonColorTeal__xtyRH:hover {
  background-color: hsl(200deg 100% 61%);
  border: 1px solid hsl(200deg 79% 76%);
  color: #fff;
}

.linkButton_buttonColorPurple__SygNW {
  /* background-color: hsl(174deg 83% 44%); */
  /* border: 1px solid hsl(277deg 79% 66%); */
  color: #fff;
  border-width: 1px;
  --tw-border-opacity: 1;
  border-color: rgb(129 140 248 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(99 102 241 / var(--tw-bg-opacity))}

.linkButton_buttonColorPurple__SygNW:hover {
  /* background-color: hsl(277deg 100% 61%); */
  /* border: 1px solid hsl(277deg 79% 76%); */
  --tw-border-opacity: 1;
  border-color: rgb(99 102 241 / var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgb(79 70 229 / var(--tw-bg-opacity))}

.linkButton_buttonColorGray__xBtAi {
  /* background-color: hsl(174deg 83% 44%); */
  background-color: #777;
  color: #fff;
  border: 3px solid #777;
}

.linkButton_buttonColorGray__xBtAi:hover {
  background-color: #777;
  color: #fff;
  border: 3px solid #777;
}






.linkButton_button__C3CTu.linkButton_isDisabled__fHH7B,
.linkButton_button__C3CTu.linkButton_isDisabled__fHH7B:hover {
  cursor: not-allowed;
  background-color: #fff;
  background: #f3f3f3;
  color: #999;
  border: 1px solid #ddd;
  pointer-events: none;
  touch-action: none;
}

.linkButton_large__bcbTm {
  font-size: 24px;
  border-radius: 6px;
  padding: 16px 32px;
  min-width: 180px;
}

.linkButton_medium__eelLF {
  border-radius: 4px;
  border-width: 1px;
  border-color: transparent;
  padding-left: 1.25rem;
  padding-right: 1.25rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  --tw-shadow: 0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow)}

.linkButton_medium__eelLF:focus{
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  --tw-ring-offset-width: 2px}

@media (min-width: 640px){

  .linkButton_medium__eelLF{
    padding-left: 2.5rem;
    padding-right: 2.5rem}}

.linkButton_small__v2oHC {
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
}

.linkButton_tiny__4PEDE {
  font-size: 12px;
  border-radius: 4px;
  padding: 4px 8px;
}

.linkButton_smallText__lhDZI {
  font-size: 14px;
  border-radius: 4px;
  padding: 8px 12px;
  background-color: transparent;
  color: #444;
  border: 1px solid transparent;
}

.linkButton_secondary__56H82 {
  background-color: #ffffff;
  border: 1px solid #ffffff;
  background: linear-gradient(75deg, #fff, #fafafa);
}

.linkButton_button__C3CTu.linkButton_secondary__56H82:hover {
  background: linear-gradient(75deg, #fff7f7, #fff9f9 );
  border: 1px solid #ffffff;
}

.linkButton_secondary__56H82.linkButton_buttonColorRed__7x9B4 {
  border-color: #ed4c47;
  color: #ed4c47;
}

.linkButton_secondary__56H82.linkButton_buttonColorRed__7x9B4:hover {
  border-color: #fa0800;
  color: #fa0800;
}

.linkButton_secondary__56H82.linkButton_buttonColorBlue__VOV9E {
  border-color: #45bded;
  color: #45bded;
}

.linkButton_secondary__56H82.linkButton_buttonColorBlue__VOV9E:hover {
  border-color: #33c5ff;
}

.linkButton_secondary__56H82.linkButton_buttonColorBlue__VOV9E {
  border-color: #45bded;
  color: #45bded;
}

.linkButton_secondary__56H82.linkButton_buttonColorBlue__VOV9E:hover {
  border-color: #33c5ff;
}

.linkButton_buttonNoWrap__3yFa6 {
  white-space: nowrap;
  padding-left: 4px;
  padding-right: 4px;
  display: flex;
  justify-content: center;
}

/* hsl(313deg 65% 68%) */
/* hsl(147deg 65% 68%) */

.BlotterLogo_blotterLogo__d_RGW {
  display: flex;
  align-items: center;
}

.BlotterLogo_icon__OY8YM {
  box-shadow: #fff7 0 0 10px;
  border-radius: 32px;
}

.BlotterLogo_text__rbwdB {
  font-family: 'Josefin Sans';
  /* line-height: 1 !important; */
  /* height: 22px; */
  /* height: 36px; */
  text-shadow: #fff7 0 0 10px;
  margin-left: 0.5rem;
  font-weight: 700;
  text-transform: uppercase;
  --tw-text-opacity: 1;
  color: rgb(61 64 92 / var(--tw-text-opacity))}

.NumberIcon_numberIcon__Eqb_J {
    position: relative;
    top: -1px;
    margin-right: 2px;
    display: inline-flex;
    height: 18px;
    width: 18px;
    align-items: center;
    justify-content: center;
    border-radius: 9999px;
    border-width: 2px;
    --tw-border-opacity: 1;
    border-color: rgb(222 49 101 / var(--tw-border-opacity));
    padding: 0.25rem;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 700;
    --tw-text-opacity: 1;
    color: rgb(222 49 101 / var(--tw-text-opacity))
}

.HowItWorksDiagram_howItWorksDiagram__xRDIh {

}

.RepurposingView_repurposingView__hWMrd {
  font-family: 'Montserrat';
}

.RepurposingView_heroIcon__NG_hj {
  flex: 0 0 300px;
  position: relative;
  margin-top: -5rem;
  margin-bottom: -1rem;
  height: 40%;
  --tw-scale-x: .75;
  --tw-scale-y: .75;
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y))}

.RepurposingView_headlineText__5U_a9 {
  flex: 1 1 100%}

@media (min-width: 640px) {

  .RepurposingView_headlineText__5U_a9 {
    margin-right: 1rem;
  }

  .RepurposingView_headlineText__5U_a9 {
    text-align: left;
  }
}

.RepurposingView_faqItem__irahc {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.RepurposingView_faqQuestion__MfZGp {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  max-width: 48rem;
  text-align: left;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 700;
}

.RepurposingView_faqAnswer___cMd6 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  max-width: 48rem;
  text-align: left;
  font-size: 1rem;
  line-height: 1.5rem;
}

